<template>
  <div :id="idName" @click="generateIf">
    <slot>Download {{ name }}</slot>
  </div>
</template>

<script>
import mapKeys from "lodash.mapkeys";
import pickBy from "lodash.pickby";
import pick from "lodash.pick";
import { saveAs } from "file-saver";
import { unparse } from "papaparse";
import * as moment from "moment";
import { planPeriodicDescription } from "@/helpers/translateString";
import {
  formatDate,
  formatDateAndHour,
  formatDateUTC,
  today,
  translateGroupDescription,
} from "@/helpers/utils";
import { backendLogCodes, loginAttemptCodes } from "@/helpers/variables";

export const isType = (value, type) => typeof value === type;
export default {
  name: "JsonCSV",
  props: {
    data: {
      type: Array,
      required: true,
    },
    fields: {
      required: false,
    },
    primaryName: {
      type: String,
      default: "",
    },
    month: {
      type: String,
      default: "",
    },
    delimiter: {
      type: String,
      default: ",",
      required: false,
    },
    fileName: {
      type: String,
      default: "",
    },
    /**
     * Should the module add SEP={delimiter}
     *
     * Useful for opening file with Excel
     */
    separatorExcel: {
      type: Boolean,
      default: false,
    },
    encoding: {
      type: String,
      default: "utf-8",
    },
    labels: {
      required: false,
    },
    prevent: {
      type: Boolean,
      default: () => false,
    },
    prepareData: {
      type: Function,
    },
  },

  beforeMount() {
    this.date;
  },

  computed: {
    idName() {
      const now = new Date().getTime();
      return "export_" + now;
    },

    name() {
      if (this.fileName) {
        return `${this.fileName}`;
      } else {
        let company = this.primaryName.replace(/ /g, "_");
        let month = this.date.replace(/ /g, "_");
        return `${company}_${month}`;
      }
    },

    date() {
      if (this.month == "") {
        return moment().format("DD-MM-YYYY");
      } else {
        return this.month;
      }
    },
  },

  methods: {
    labelsFunctionGenerator() {
      if (
        !isType(this.labels, "undefined") &&
        !isType(this.labels, "function") &&
        !isType(this.labels, "object")
      ) {
        throw new Error("Labels needs to be a function(value,key) or object.");
      }
      if (isType(this.labels, "function")) {
        return (item) => {
          let _mapKeys = mapKeys(item, this.labels);
          return _mapKeys;
        };
      }
      if (isType(this.labels, "object")) {
        return (item) => {
          return mapKeys(item, (item, key) => {
            return this.labels[key] || key;
          });
        };
      }
      return (item) => item;
    },
    fieldsFunctionGenerator() {
      if (
        !isType(this.fields, "undefined") &&
        !isType(this.fields, "function") &&
        !isType(this.fields, "object") &&
        !Array.isArray(this.fields)
      ) {
        throw new Error("Fields needs to be a function(value,key) or array.");
      }
      if (
        isType(this.fields, "function") ||
        (isType(this.fields, "object") && !Array.isArray(this.fields))
      ) {
        return (item) => {
          return pickBy(item, this.fields);
        };
      }
      if (Array.isArray(this.fields)) {
        return (item) => {
          return pick(item, this.fields);
        };
      }
      return (item) => item;
    },
    cleaningData() {
      if (
        isType(this.fields, "undefined") &&
        isType(this.labels, "undefined")
      ) {
        return this.data;
      }
      const labels = this.labelsFunctionGenerator();
      const fields = this.fieldsFunctionGenerator();
      return this.data.map((item) => {
        let newData = labels(fields(item));
        if ("Periodicidade" in newData) {
          newData["Periodicidade"] =
            planPeriodicDescription[newData["Periodicidade"]];
        }

        return newData;
      });
    },
    generateIf() {
      if (!this.prevent) {
        this.generate();
      }
    },
    generate() {
      this.$emit("export-started");
      const dataExport = (this.prepareData || this.prepareDataToExport)(
        this.data
      );
      if (!dataExport) {
        console.error("No data to export");
        return;
      }
      let csv = unparse(
        dataExport,
        Object.assign(
          {
            delimiter: this.delimiter,
            encoding: this.encoding,
          },
          this.advancedOptions
        )
      );
      if (this.separatorExcel) {
        csv = "SEP=" + this.delimiter + "\r\n" + csv;
      }
      if (this.encoding === "utf-8") {
        csv = "\ufeff" + csv;
      }
      this.$emit("export-finished");
      if (!this.testing) {
        let blob = new Blob([csv], {
          type: "application/csvcharset=" + this.encoding,
        });
        saveAs(blob, `${this.name}_${formatDate(today)}.csv`);
      }
    },
    getUsersEmails(userObjects) {
      const usersEmails = [];

      userObjects.forEach((user) => {
        usersEmails.push(user.email);
      });
      return usersEmails.toString().replace(",", ", ");
    },
    prepareDataToExport(data) {
      const filteredData = data.map(
        ({ created_at, status, event, author_user }) => {
          status.code = status.code.startsWith("teste")
            ? "USER_SUCCESS"
            : status.code;

          let description;
          if (loginAttemptCodes.includes(status.code)) {
            description = this.$ml.get(status.code.toLowerCase());
          } else if (status.code === backendLogCodes.LOGOUT_SUCCESS) {
            description = `${author_user} ${this.$ml.get(
              "force_logout_description"
            )} ${status.logged_out_user}`;
          } else if (
            [
              backendLogCodes.PONTOMAIS_AUTO_LOGOUT,
              backendLogCodes.SENIOR_AUTO_LOGOUT,
            ].includes(status.code)
          ) {
            description = this.$ml.get("pontomais_auto_logout_description");
          } else if (status.code === backendLogCodes.ADD_USERS_TO_GROUP) {
            event = this.$ml.get("add_users_to_group");
            description = `${this.$ml.get("add_users_to_group")} ${
              status.group[0].name
            }: ${this.getUsersEmails(status.users)}`;
          } else if (status.code === backendLogCodes.REMOVE_USERS_FROM_GROUP) {
            event = this.$ml.get("remove_users_from_group");
            description = `${this.$ml.get("remove_users_from_group")} ${
              status.group[0].name
            }: ${this.getUsersEmails(status.users)}`;
          } else if (status.code === backendLogCodes.ADVANCED_AUTO_LOGOUT) {
            author_user = status.logged_out_user;
            description = this.$ml.get("closed_office");
          } else if (status.code === backendLogCodes.SINGLE_ACCESS_LOGOUT) {
            author_user = status.logged_out_user;
            description = this.$ml.get("end_of_single_access");
          } else if (status.code === backendLogCodes.ADD_GROUPS_TO_USER) {
            event = this.$ml.get("add_groups_to_user");
            let groupsName = status.group.map(({ name }) => name);
            description = `${status.users[0].email} ${this.$ml.get(
              "user_added_on_shifts"
            )} ${groupsName}`;
          } else if (status.code === backendLogCodes.REMOVE_GROUPS_FROM_USER) {
            event = this.$ml.get("remove_groups_from_user");
            let groupsName = status.group.map(({ name }) => name);
            description = `${status.users[0].email} ${this.$ml.get(
              "user_removed_from_shifts"
            )} ${groupsName}`;
          } else if (
            status.code === backendLogCodes.USER_REQUEST_SINGLE_ACCESS
          ) {
            description = `${author_user} ${this.$ml.get(
              "requested_single_access"
            )} ${this.$ml.get("to_admins")}`;
          } else if (
            status.code === backendLogCodes.USER_REQUEST_PASSWORD_CHANGE
          ) {
            description = `${author_user} ${this.$ml.get(
              "requested_password_change_description"
            )} ${this.$ml.get("to_admins")}`;
          } else if (status.code === backendLogCodes.CHANGE_USER_PASSWORD) {
            event = this.$ml.get("user_password_change");
            description = `${author_user} ${this.$ml.get(
              "changed_the_password_of"
            )} ${status.target_user}`;
          } else if (status.code === backendLogCodes.CHANGE_OWN_PASSWORD) {
            event = this.$ml.get("user_password_change");
            description = `${author_user} ${this.$ml.get(
              "change_own_password"
            )}`;
          } else if (status.code === backendLogCodes.ADD_VACATION_TO_USER) {
            event = this.$ml.get("vacation_period");
            description = `${this.$ml.get("add_vacation_to_user")} "${
              status.target_user
            }" ${this.$ml.get("from").toLowerCase()} ${formatDateUTC(
              status.start_date
            )} ${this.$ml.get("until")} ${formatDateUTC(status.end_date)}`;
          } else if (
            status.code === backendLogCodes.REMOVE_VACATION_FROM_USER
          ) {
            event = this.$ml.get("vacation_period");
            description = `${this.$ml.get("remove_vacation_from_user")} "${
              status.target_user
            }"`;
          } else if (status.code === backendLogCodes.ACTIVATE_SSO) {
            event = this.$ml.get("user_activate_sso_event");
            description = `${author_user} ${this.$ml.get("user_activate_sso")}`;
          } else if (status.code === backendLogCodes.DISABLE_SSO) {
            event = this.$ml.get("user_disable_sso_event");
            description = `${author_user} ${this.$ml.get("user_disable_sso")}`;
          } else if (
            status.code === backendLogCodes.RECEIVE_USER_SINGLE_ACCESS
          ) {
            description = `${status.target_user} ${this.$ml.get(
              "granted_single_access"
            )} ${this.$ml.get("to")} ${author_user}`;
          } else if (status.code === backendLogCodes.LOST_USER_SINGLE_ACCESS) {
            description = `${status.target_user} ${this.$ml.get(
              "removed_single_access_from"
            )} ${author_user}`;
          } else if (
            status.code === backendLogCodes.UPDATED_GROUP_RULES &&
            translateGroupDescription(status.message, this.$ml)
          ) {
            description = translateGroupDescription(status.message, this.$ml);
          } else if (status.code === backendLogCodes.ADD_ADMIN_PERMISSION) {
            description = `${status.target_user} ${this.$ml.get(
              "has_been_added_admin"
            )}`;
          } else if (status.code === backendLogCodes.REMOVE_ADMIN_PERMISSION) {
            description = `${status.target_user} ${this.$ml.get(
              "is_no_longer_an_administrator"
            )}`;
          } else if (status.code === backendLogCodes.CUSTOMIZE_LOGIN) {
            if (status.data) {
              description = "";

              Object.keys(status.data).forEach((key) => {
                description += `${this.$ml.get(key)}: ${status.data[key]}\n`;
              });
            }
          } else {
            description = this.$ml.get(status.code.toLowerCase(), event);
          }

          return {
            [this.$ml.get("date")]: formatDateAndHour(created_at, true),
            [this.$ml.get("event")]: event,
            [this.$ml.get("description")]: description,
            [this.$ml.get("browser")]: status.browser,
            [this.$ml.get("operational_system")]: status.operational_system,
            [this.$ml.get("ip")]: status.ip,
            [this.$ml.get("author")]: author_user || this.$ml.get("system"),
          };
        }
      );

      return filteredData;
    },
  },
};
</script>

<style scoped>
div {
  display: inline;
}
</style>
